.rcw-widget-container {
    width: 800px !important;
    max-width: 90vw !important;
  }
  
  .rcw-conversation-container {
    width: 800px !important;
  }
  
  .rcw-messages-container {
    width: 100% !important;
  }
  
  .rcw-message {
    max-width: 100% !important;
  }

  .rcw-response {
    max-width: 100% !important;
  }

  .rcw-message-text {
    max-width: 100% !important;
  }

  .rcw-launcher {
    width: 60px !important;
    height: 60px !important;
  }